import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {SelectTabService} from "../../Service/select-tab.service";
import {ChangeRequestStoreService} from "../../store/change-request-store.service";
import {DeviceService} from "../../Service/device.service";
import {RequestResponse} from "../../model/RequestRespne";




@Component({
  selector: 'app-closes-requests',
  templateUrl: './closed-requests.component.html',
  styleUrl: './closed-requests.component.scss'
})
export class ClosedRequestsComponent implements OnInit{
  displayedColumns: string[] = ['id', 'subject', 'workflow', 'status', 'owner', 'created', 'lastUpdated'];
  dataSource: RequestResponse[] = [];
  groupDataSource :RequestResponse[] = [];

  isGroupTableVisible = false;
  isTableVisible = false;
  constructor(private router:Router,
              private route: ActivatedRoute,
              private tabservice: SelectTabService,
              private changeRequestStore : ChangeRequestStoreService,
              private deviceService: DeviceService,

  ) {
    this.changeRequestStore.getRequest();
  }

  ngOnInit(): void {
    this.changeRequestStore.closedChangeRequest$.subscribe({
      next: (data) => {
        if (data !=  null){
          this.dataSource = data;
        }
      },
      error: (err) => {
        console.error('Fehler beim Laden der Daten:', err);
      }
    });
  }

  getFieldValue(request: RequestResponse, fieldName: string): string {
    const field = request.data.fields.find(f => f.name === fieldName);
    return field ? field.values[0] : '';
  }

  toggleTableVisibility(): void {
    this.isTableVisible = !this.isTableVisible;
  }

  toggleGroupTableVisibility(): void {
    this.isGroupTableVisible = !this.isGroupTableVisible;
  }

  selectRow(row: RequestResponse) {
    const tabNummer = this.tabservice.getTabNumberBasedOnStatus(row);
    this.tabservice.selectTab(tabNummer);
    this.tabservice.changeData(row);
    this.router.navigate(["../offenes-Ticket"],{relativeTo: this.route});
    this.deviceService.setChangeRequestId(row.data.id);

  }
}
