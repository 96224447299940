import { NgModule } from '@angular/core';
import {SharedModule} from "../../Shared/shared.module";
import {ClosedRequestsComponent} from "./closed-requests.component";
import {ClosedRequestsRoutingModule} from "./closed-requests.routing.module";


@NgModule({
  declarations: [ClosedRequestsComponent],
  imports: [
    SharedModule,
    ClosedRequestsRoutingModule
  ]
})
export class ClosedRequestsModule {}
