<h1 class="header">Closed Change Requests</h1>

    <div class="content">
      <div class="flex-container">
        <button mat-icon-button (click)="toggleTableVisibility()">
          <mat-icon>{{ isTableVisible ? 'expand_more' : 'chevron_right' }}</mat-icon>
        </button>
        <p class="p">My Closed Change Requests</p>
      </div>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" *ngIf="isTableVisible">

        <!-- ID Column -->
        <ng-container matColumnDef="id" >
          <th mat-header-cell  *matHeaderCellDef  class="mat-header-cell"> Id </th>
          <td  mat-cell (click)="selectRow(element)" *matCellDef="let element" class="blue"> {{ element?.data?.id}} </td>
        </ng-container>

        <!-- Subject Column -->
        <ng-container matColumnDef="subject">
          <th mat-header-cell *matHeaderCellDef class="mat-header-cell"> Subject </th>
          <td mat-cell (click)="selectRow(element)" *matCellDef="let element" class="subject"> {{ getFieldValue(element, 'Subject')}} </td>
        </ng-container>

        <!-- Workflow Column -->
        <ng-container matColumnDef="workflow">
          <th mat-header-cell *matHeaderCellDef class="mat-header-cell"> Workflow </th>
          <td mat-cell *matCellDef="let element"> {{getFieldValue(element, 'Workflow')}} </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef class="mat-header-cell"> Status </th>
          <td mat-cell *matCellDef="let element"> {{getFieldValue(element, 'status')}} </td>
        </ng-container>

        <!-- Owner Column -->
        <ng-container matColumnDef="owner">
          <th mat-header-cell *matHeaderCellDef class="mat-header-cell"> Owner </th>
          <td mat-cell *matCellDef="let element"> {{getFieldValue(element, 'Owner')}} </td>
        </ng-container>

        <!-- Created Column -->
        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef class="mat-header-cell"> Created </th>
          <td mat-cell *matCellDef="let element"> {{ getFieldValue(element, 'created') }} </td>
        </ng-container>

        <!-- Last Updated Column -->
        <ng-container matColumnDef="lastUpdated">
          <th mat-header-cell *matHeaderCellDef class="mat-header-cell"> Last Updated </th>
          <td mat-cell *matCellDef="let element"> {{getFieldValue(element, 'LastUpdated')}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-divider class="divider"></mat-divider>

      <div class="flex-container">
        <button mat-icon-button (click)="toggleGroupTableVisibility()">
          <mat-icon>{{ isGroupTableVisible ? 'expand_more' : 'chevron_right' }}</mat-icon>
        </button>
        <p class="p">Group Change Requests</p>

      </div>
      <table mat-table [dataSource]="groupDataSource" class="mat-elevation-z8" *ngIf="isGroupTableVisible">

        <!-- ID Column for Group Change Requests -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef class="mat-header-cell"> Id </th>
          <td mat-cell (click)="selectRow(element)" *matCellDef="let element" class="blue"> {{element.data.id}} </td>
        </ng-container>

        <!-- Subject Column -->
        <ng-container matColumnDef="subject">
          <th mat-header-cell *matHeaderCellDef class="mat-header-cell"> Subject </th>
          <td mat-cell *matCellDef="let element"> {{ getFieldValue(element, 'Subject') }} </td>
        </ng-container>

        <!-- Workflow Column -->
        <ng-container matColumnDef="workflow">
          <th mat-header-cell *matHeaderCellDef class="mat-header-cell"> Workflow </th>
          <td mat-cell *matCellDef="let element"> {{ getFieldValue(element, 'Workflow') }} </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef class="mat-header-cell"> Status </th>
          <td mat-cell *matCellDef="let element"> {{ getFieldValue(element, 'status') }} </td>
        </ng-container>

        <!-- Owner Column -->
        <ng-container matColumnDef="owner">
          <th mat-header-cell *matHeaderCellDef class="mat-header-cell"> Owner </th>
          <td mat-cell *matCellDef="let element"> {{ getFieldValue(element, 'Owner') }} </td>
        </ng-container>

        <!-- Created Column -->
        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef class="mat-header-cell"> Created </th>
          <td mat-cell *matCellDef="let element">{{ getFieldValue(element, 'due') }} </td>
        </ng-container>

        <!-- Last Updated Column -->
        <ng-container matColumnDef="lastUpdated">
          <th mat-header-cell *matHeaderCellDef class="mat-header-cell"> Last Updated </th>
          <td mat-cell *matCellDef="let element"> {{ getFieldValue(element, 'LastUpdated') }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>


